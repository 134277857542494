import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/rent',
    name: 'Rent',
    component: () => import('../views/Rent')
  },
  {
    path: '/needs',
    name: 'Needs',
    component: () => import('../views/Needs')
  },
  {
    path: '/safety',
    name: 'Safety',
    component: () => import('../views/Safety')
  }
]

const router = createRouter({
  // eslint-disable-next-line no-undef
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
