import { createI18n } from 'vue-i18n'

function loadLocaleMessages () {
  // eslint-disable-next-line no-undef
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {
    ru: {
      ПроцедураАренды: 'Процедура аренды',
      Требования: 'Требования к аккаунту',
      Безопасность: 'Безопасность',
      Доход: 'Получай пассивный доход за аренду facebook аккаунта!',
      выплаты: 'Стабильные выплаты 400 грн в месяц',
      оплата: '*оплата раз в 2 недели',
      ЗаполнитеФорму: 'Заполните форму и с вами свяжется менеджер',
      имя: 'Ваше имя:',
      мессенджер: 'Удобный вид связи (мессенджер):',
      данные: 'Для работы нам понадобятся такие данные:',
      аккаунт: 'Логин/пароль аккаунта Фейсбук',
      почта: 'Актуальная почта',
      номертелефона: ' Контактный номер телефона (мессенджер)',
      карты: 'Номер карты для выплат',
      info: 'После получения всех данных нам нужно от 2 до 3 дней, чтобы проверить ваш аккаунт Фейсбук, если все подходит, можем начинать работу.',
      info1: ' Мы работаем',
      info2: 'по предоплате:',
      info3: 'вначале вы получаете деньги, а потом мы начинаем работу с вашим аккаунтом. Оплата будет приходить на указанную карту каждые 2 недели по четвергам.',
      info4: 'Иногда нам нужны будут коды подтверждения от Фейсбук, которые приходят на почту или смс. В таком случае, мы с вами свяжемся в указанном мессенджере.',
      профиль: 'Реальный профиль',
      Возраст: 'Возраст',
      Аккаунт: 'Аккаунту больше года',
      фото: 'Наличие 2-3 реальных фото',
      друзі: 'друзей',
      аренда: 'Не сдавался в аренду ранее',
      info5: ' Мы гарантируем безопасность нашим клиентам. Реклама, которую мы показываем не будет видна вашим друзьям и подписчикам, она транслируется исключительно от имени бренда.',
      info6: ' У вас сохраняется доступ к аккаунту и вы можете использовать все его функции (отправка сообщений, возможность\n' +
        'создавать страницы, группы и тд).',
      info7: 'Ваш личный профиль Фейсбук блокировать не будет. Максимальный риск – запрет показа рекламы с аккаунта.',
      info8: ' Мы не отправляем спам!',
      info9: 'Для чего мы арендуем профиля?',
      info10: ' Мы занимаемся продвижением товаров и услуг разных компаний (книги, приложения, сайты знакомств, магазины\n' +
        'одежды). Так, как у нас много клиентов, для запуска рекламы нам нужны новые аккаунты.',
      tel: 'Номер телефона:'
    },
    ua: {
      ПроцедураАренды: 'Процедура оренди',
      Требования: 'Вимоги до профілю',
      Безопасность: 'Безпека',
      Доход: 'Отримуй пасивний прибуток за оренду профіля facebook!',
      выплаты: 'Стабільні виплати 400 грн в місяць',
      оплата: '*оплата раз на 2 тижні',
      ЗаполнитеФорму: 'Заповніть форму і з вами звʼяжеться менеджер',
      имя: 'Ваше імʼя:',
      мессенджер: 'Зручний спосіб звʼязку:',
      данные: 'Для роботи нам потрібні:',
      аккаунт: 'Логін/пароль від профілю',
      почта: 'Актуальна пошта',
      номертелефона: 'Контактний номер телефону (месенджер)',
      карты: 'Номер карти для виплат',
      info: 'Після отримання всіх даних нам потрібно від 2 до 3 днів, щоб перевірити ваш обліковий запис Фейсбук, якщо все підходить, можемо починати роботу.,',
      info1: ' Ми працюємо',
      info2: 'по передоплаті:',
      info3: 'спочатку ви отримуєте гроші, а потім ми починаємо роботу з вашим профілем. Оплата приходитиме на вказану картку кожні 2 тижні по четвергам.',
      info4: 'Іноді нам потрібні будуть коди підтвердження від Фейсбук, які надходять на пошту або по смс. У такому разі ми з вами зв\'яжемося у вказаному месенджері.',
      профиль: 'Реальний профіль',
      Возраст: 'Вік',
      Аккаунт: 'Аккаунту більше року',
      фото: 'Наявність 2-3 реальних фото',
      друзі: 'друзів',
      аренда: 'Не здавався в оренду раніше',
      info5: 'Ми гарантуємо безпеку наших клієнтів. Реклама, яку ми показуємо, не буде видна вашим друзям та підписникам, вона транслюється виключно від імені бренду.',
      info6: 'У вас зберігається доступ до облікового запису і ви можете використовувати всі його функції (надсилання повідомлень, можливість створювати сторінки, групи тощо).',
      info7: 'Ваш особистий профіль Фейсбук не блокуватиме. Максимальний ризик – заборона показу реклами з облікового запису.\n',
      info8: 'Ми не відправляємо спам!',
      info9: 'Навіщо ми орендуємо профілю?',
      info10: 'Ми займаємося просуванням товарів та послуг різних компаній (книги, додатки, сайти знайомств, магазини одягу). Так як у нас багато клієнтів, для запуску реклами нам потрібні нові облікові записи.',
      tel: 'Номер телефона:'
    }
  }
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

export default createI18n({
  // eslint-disable-next-line no-undef
  locale: process.env.VUE_APP_I18N_LOCALE || 'ru',
  // eslint-disable-next-line no-undef
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ukr',
  messages: loadLocaleMessages()
})
