<template>
  <div class="home">
    <NavBar/>
    <MainPart/>
    <ContactForm/>
  </div>
</template>

<script>
import NavBar from '../components/NavBar'
import MainPart from '../components/MainPart'
import ContactForm from '../components/ContactForm'

export default {
  name: 'Home',
  components: {
    ContactForm,
    MainPart,
    NavBar
  }
}
</script>

<style lang="scss">
@import "../assets/base.css";

.home{
  background-color: var(--bg-color);
  height: 100vh;
}
</style>
