<template>
  <div class="contact-form">
    <div class="contact-form-text">
      <h4>
        {{ $t('ЗаполнитеФорму') }}
      </h4>
      <h3>
        <a href="tel:+380631708044">
          +380631708044 (Телеграм, Вайбер)
        </a>
      </h3>
    </div>
    <form
        method="post"
        @submit.prevent="onSubmit"
        id="form"
    >
      <label for="name">
        {{ $t('имя') }}
      </label>
      <input
          type="text"
          name="name"
          id="name"
          v-model.trim="form.name">
      <template v-if="v$.form.name.$dirty">
        <div class="error" v-for="error of v$.form.name.$silentErrors" :key="error.$message">
          <div>{{ error.$message }}</div>
        </div>
      </template>
      <label for="telephone">
        {{ $t('tel') }}
      </label>
      <input
          v-model.trim="form.telephone"
          id="telephone"
          type="tel"
          placeholder="0931234567"
          name="telephone">
      <template v-if="v$.form.telephone.$dirty">
        <div
             v-for="error of v$.form.telephone.$silentErrors"
             :key="error.$message"
             class="error">
          <div>{{ error.$message }}</div>
        </div>
      </template>
      <p>
        {{ $t('мессенджер') }}
      </p>
      <select id="messenger" v-model="form.messenger">
        <option value="viber">Viber</option>
        <option value="telegram">Telegram</option>
      </select>
      <template v-if="v$.form.messenger.$dirty">
        <div class="error" v-for="error of v$.form.messenger.$silentErrors" :key="error.$message">
          <div>{{ error.$message }}</div>
        </div>
      </template>
      <p class="success" v-if="successMessage">{{ successMessage }}</p>
      <p class="errorMessage" v-if="errorMessage">{{ errorMessage }}</p>
      <button class="submit" type="submit">Отправить</button>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required, minLength, numeric, maxLength} from '@vuelidate/validators'

export default {
  name: 'ContactForm',
  setup() {
    return {v$: useVuelidate()}
  },
  data: () => ({
    form: {
      name: '',
      telephone: '',
      messenger: '',
    },
    successMessage: '',
    errorMessage: ''
  }),
  validations() {
    return {
      form: {
        name: {
          required,
          minLength: minLength(2)
        },
        telephone: {
          required,
          numeric,
          minLength: minLength(10),
          maxLength: maxLength(10)
        },
        messenger: {
          required
        }
      }
    }
  },

  methods: {
     onSubmit() {
      const formData = {
         name: this.form.name,
         telephone: this.form.telephone,
         messenger: this.form.messenger
      }
      this.v$.$validate();
      if (!this.v$.$error) {
        fetch('contact.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: Object.entries(formData).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&')
        }).then((res) => {
          console.log(res);
          this.form = '';
          this.v$.form.telephone.$dirty = false;
          this.v$.form.name.$dirty = false;
          this.v$.form.messenger.$dirty = false;
          this.successMessage = 'Ваша заявка прийнята. Очікуйте повідомлення протягом 24 годин.';
          setTimeout(() => {
            this.successMessage = null;
          }, 7000)
        })
      } else {
        console.log('error!!!');
        this.errorMessage = 'Щось пішло не так, спробуйте відправити заявку ще раз';
        setTimeout(() => {
          this.errorMessage = null;
        }, 7000)
      }
    }
  }
}
</script>

<style scoped>
.error {
  color: red;
  font-size: 12px;
  margin: 5px auto 0;
}

.errorMessage, .success {
  margin: 10px auto;
  padding: 10px;
  text-align: center;
  border: 2px solid;
}

.errorMessage{
  color: red;
}

.success {
  color: green;
}

.submit {
  padding: 10px;
  border-radius: 10px;
  background-color: #9370DB;
  box-shadow: 2px 2px 10px #000;
  transition: all 0.3s ease-in-out;
  color: #fff;
  margin-top: 20px;
  cursor: pointer;
}

.contact-form {
  background-color: var(--bg-color);
  display: flex;
  padding: 0 10% 10%;
  width: 100%;
}

h4 {
  padding-top: 40px;
  font-size: 32px;
  max-width: 540px;
}

h3 {
  margin-top: 20px;
}

form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
//margin-left: 20px;
}

p, label {
  margin-bottom: 10px;
}

label {
  margin-top: 10px;
}

p {
  margin-top: 10px;
  max-width: 300px;
}

input, select {
  max-width: 300px;
  height: 35px;
  border-radius: 10px;
  padding: 5px;
  font-size: 18px;
  outline: none;
}

@media (max-width: 575px) {
  .contact-form {
    flex-direction: column;
  //padding-left: 4%;
  }

  h4 {
    padding-top: 30px;
    font-size: 22px;
    max-width: 540px;
  }

  select {
    font-size: 14px;
  }
}

</style>
