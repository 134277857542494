<template>
<div class="top-block">
  <div class="text-block">
    <h2>
      {{$t('Доход')}}
    </h2>
    <h2>
      {{$t('выплаты')}}
    </h2>
    <h3>
      {{ $t('оплата') }}
    </h3>
  </div>
  <picture class="picture">
    <img src="../assets/imgs/1.png" alt="top">
  </picture>
</div>
</template>

<script>
export default {
  name: 'MainPart'
}
</script>

<style scoped lang="scss">
.top-block{
  background-color: var(--main-bg);
  position: relative;
}

.text-block{
  position: absolute;
  top: 20%;
  left: 10%;
  max-width: 540px;
  color: #fff;
}

h2{
  padding: 15px;
  margin-bottom: 10px;
  font-size: 34px;
}

h3{
  padding: 0 0 0 15px;
}

@media (max-width: 575px) {
  .text-block{
    top: 0;
    left: 0;
  }
  h2{
    font-size: 18px;
    margin-bottom: 0px;
    padding: 10px 10px 5px;
    max-width: 65%;
  }
}

</style>
