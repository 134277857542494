<template>
  <div id="nav">
    <div class="flags">
      <a href="#" @click="setLocale('ua')">
        <flag iso="ua"></flag>
      </a>
      <span>/</span>
      <a href="#" @click="setLocale('ru')">
        RU
      </a>
    </div>
  </div>
  <router-view/>
</template>

<script>
export default {
  components: {
    // Home
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale
    }
  }
}
</script>

<style lang="scss">

.flags {
  display: flex;
  justify-content: end;
  margin-right: 10px;
  padding-top: 5px;

  & span {
    padding: 0 2px;
  }
}

</style>
