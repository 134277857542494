<template>
  <nav>
    <ul>
      <li>
        <router-link to="/rent">
          {{ $t('ПроцедураАренды') }}
        </router-link>
      </li>
      <li>
        <router-link to="/needs">
          {{ $t('Требования') }}
        </router-link>
      </li>
      <li>
        <router-link to="/safety">
          {{ $t('Безопасность') }}
        </router-link>
      </li>
    </ul>
  </nav>

</template>

<script>
export default {
  name: 'NavBar'
}
</script>

<style scoped lang="scss">
nav{
  background: var(--bg-color);
}
ul{
  display: flex;
  justify-content: center;
  padding: 20px;
}
li{
  margin-right: 20px;
}
a{
  padding: 10px;
  border-radius: 10px;
  background-color: #9370DB;
  box-shadow: 2px 2px 10px #000;
  transition: all 0.3s ease-in-out;
  color: #fff;
  &:last-child{
    margin-right: 0;
  }
  &:hover{
    box-shadow: none;
    transform: scale(1.05);
  }
}
a.router-link-exact-active{
  box-shadow: none;
  transform: scale(1.05);
}

@media (max-width: 575px) {
  ul, a{
    padding: 10px;
    font-size: 10px;
  }
  a{
    flex-wrap: wrap;
    display: flex;
  }
  li{
    margin-right: 5px;
  }
}

</style>
